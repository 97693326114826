// import { useSnackbar } from "notistack";
import React, { Dispatch, useState } from 'react';
import { toast } from 'react-toastify';

import ERPC from '@etclabscore/ethereum-json-rpc';

export const DEFAULT_RPC_URL = "";

function useCoreGeth(
  env: string,
  queryUrlOverride?: string,
): [ERPC, Dispatch<string>] {
  // const { enqueueSnackbar } = useSnackbar();
  const [erpc, setErpc] = React.useState<ERPC>();
  const [urlOverride, setUrlOverride] = useState(queryUrlOverride || DEFAULT_RPC_URL);
  React.useEffect(() => {
    if (!urlOverride) {
      // toast.error(`No network provided, can add network first or use ${window.location.protocol}//${window.location.host}?rpcUrl=<Endpoint>`)
      return
    }
    const runAsync = async () => {
      let parsedUrl;
      try {
        parsedUrl = new URL(urlOverride);
      } catch (e) {
        return;
      }
      let rpc;
      let fallbackPort
      let type: "websocket" | "http" | "https" | "postmessagewindow" | "postmessageiframe"
      let protocol = parsedUrl.protocol.split(":")[0] as any;
      switch (protocol) {
        case "ws":
          type = "websocket";
          protocol = "ws://"
          fallbackPort = 80;
          break;
        case "wss":
          type = "websocket";
          protocol = "wss://"
          fallbackPort = 443
          break;
        case "https":
          type = protocol = "https";
          fallbackPort = 443
          break;
        default:
          type = protocol = "http";
          fallbackPort = 80
          break;
      }
      const port = parseInt(parsedUrl.port, 10);
      rpc = new ERPC({
        transport: {
          host: parsedUrl.hostname,
          port: port ? port : fallbackPort,
          type: type,
          path: parsedUrl.pathname,
          protocol: protocol
        },
      });
      await rpc.eth_chainId()
      toast.success(`Successfully connected to ${urlOverride}`)
      if (rpc) {
        setErpc(rpc);
      }
    };
    runAsync().catch((e) => {
      toast.error(`Failed to connect ${urlOverride}: ${e}`)
      // toast.warn(`Redirecting to default network ...`, { onClose: () => window.location.search = "?network=_default_" })
      console.error(e)
    });
    return () => {
      if (erpc) {
        erpc.rpc.requestManager.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env, urlOverride, queryUrlOverride]);
  return [erpc as ERPC, setUrlOverride];
}

export default useCoreGeth;
