import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";
import { ReactComponent as BlockHeight } from "./assets/BlockHeight.svg";
import { ReactComponent as GasPrice } from "./assets/GasPrice.svg";
import { ReactComponent as ChainID } from "./assets/ChainID.svg";
import { ReactComponent as Peers } from "./assets/Peers.svg";
import { ReactComponent as Search } from "./assets/Search.svg";
import { ReactComponent as AddChain } from "./assets/AddChain.svg";
import { ReactComponent as Document } from "./assets/Document.svg";

export const BlockHeightIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={BlockHeight} />;
};

export const GasPriceIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={GasPrice}></SvgIcon>;
};

export const ChainIDIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={ChainID}></SvgIcon>;
};
export const PeersIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={Peers}></SvgIcon>;
};
export const SearchIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={Search}></SvgIcon>;
};
export const AddChainIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={AddChain}></SvgIcon>;
};
export const DocumenthIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} component={Document}></SvgIcon>;
};
