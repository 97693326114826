export default {
    // Moment integration
    "Timestamp Date": "{{date, date}}",
    "Change Language": "Cambiar Lenguaje",
    "Enter an Address, Transaction Hash or Block Number": "Ingresar una Trasacción, Hash de Transacción or Numero de Bloque",
    "JSON-RPC API Documentation": "JSON-RPC API Documentación",
    "Expedition Github": "Expedition Github",
    "Toggle Dark Mode": "Modo Oscuro",
    "Block Height": "Altura del Bloque",
    "Chain ID": "ID Cadena",
    "Syncing": "Sincronizando",
    "Gas Price": "Precio del Gas",
    "Network Hash Rate": "Network Hash Rate",
    "Nodes": "Nodos",
    "Hash Rate": "Block transactions / Block size",
    "Transaction Count": "Cantidad de Transacciones",
    "Gas Usage": "Uso Gas ",
    "Uncles": "Gas used / Block transactions",
    "Top Miners": "Top Mineros",
    "Top Miners by extraData": "Top Mineros by extraData",
    "Top Miners by Address": "Top Mineros by Address",
    "Block Number": "Numero de Bloque",
    "Hash": "Hash",
    "Timestamp": "Timestamp",
    "Transactions": "Transacciones",
    "Number": "Números",
    "ParentHash": "ParentHash",
    "Miner": "Minero",
    "Nonce": "Nonce",
    "Difficulty": "Dificultad",
    "Extra Data": "Datos Extra ",
    "State Root": "State Root",
    "Transaction Root": "Transaction Root",
    "Receipts Root": "Receipts Root",
    "Block": "Bloque",
    "Gas Used": "Gas utilizado",
    "Cumulative Gas Used": "Gas Utilizado Acumulado",
    "Value in Ether": "Valor en Ether",
    "From": "Desde",
    "To": "Hacia",
    "Contract Address": "Dirección de Contrato",
    "Transaction Index": "Índice de Transacción",
    "Receipt Status": "Estado de Receipt ",
    "Receipt Logs": "Logs de Receipt",
    "No Transactions for this block range.": "No hay Transacciones para este rango de bloque.",
    "Address": "Dirección",
    "Balance": "Balance",
    "Code": "Código",
    "Configuration": "Configuración",
    "Back": "Atras",
    "Custom RPC" : "Custom RPC",

  };
