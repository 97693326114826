import * as React from 'react';

import EthereumJSONRPC, { Block as IBlock } from '@etclabscore/ethereum-json-rpc';
import { CircularProgress } from '@material-ui/core';

import BlockRaw from '../components/BlockRaw';
import useCoreGethStore from '../stores/useCoreGethStore';

export default function BlockRawContainer(props: any) {
  const { match: { params: { hash } } } = props;
  const [erpc]: [EthereumJSONRPC, any] = useCoreGethStore();
  const [block, setBlock] = React.useState<IBlock>();
  React.useEffect(() => {
    if (!erpc) { return; }
    erpc.eth_getBlockByHash(hash, true).then((b) => {
      if (b === null) { return; }
      setBlock(b);
    });
  }, [hash, erpc]);
  if (!block) { return (<CircularProgress />); }
  return (<BlockRaw block={block} />);
}
