import * as React from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import {
  languageAliases,
  reverseSupportedLanguages,
  supportedLanguages,
} from "../../i18n";

const LanguageMenu: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (lang: string) => {
    setAnchorEl(null);
    // this forces language change for react + i18n react
    i18n.changeLanguage(reverseSupportedLanguages[lang]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    i18n.changeLanguage(
      reverseSupportedLanguages[languageAliases[i18n.language]]
    );
  }, [i18n]);

  console.log(
    "src/containers/LanguageMenu/LanguageMenu.tsx",
    i18n.language,
    languageAliases[i18n.language]
  );
  return (
    <>
      <Tooltip title={t("Change Language") as string}>
        <Button onClick={handleClick}>{languageAliases[i18n.language]}</Button>
      </Tooltip>
      <Menu
        className="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.values(supportedLanguages).map((lang, i) => (
          <MenuItem
            selected={
              i18n.language !== "" && languageAliases[i18n.language] === lang
            }
            onClick={(event) => handleMenuItemClick(lang)}
            key={lang}
          >
            {lang}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(LanguageMenu);
