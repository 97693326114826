import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

//import grey from "@material-ui/core/colors/grey";

export const altTheme = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiAppBar: {
        position: "sticky",
      },
      MuiCard: {
        elevation: 0,
      },
    },
    palette: {
      type: "dark",
      primary: {
        light: "#AFB0CC",
        main: "#9092FE",
        dark: "#6667AB",
        contrastText: "#fff",
      },
      background: {
        default: "#17171c",
        paper: "#3D3D49",
      },
    },
    typography: {
      fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
            // WebkitFontSmoothing: 'auto',
          },
        },
      },
      MuiAppBar: {
        root: {
          background: "linear-gradient(180deg, #2F2E56 0%, #17171C 95%)",
        },
      },
      MuiTextField: {
        root: {
          overflow: "visible !important",
          color: "#FFF ",
        },
      },
      MuiPaper: {
        root: {
          overflow: "visible !important",
          // color: "#EA5AA2",
          // backgroundColor: "#000000",
        },
      },
      MuiTable: {
        root: {
          color: "#53CBC9 visible !important",
        },
      },
      MuiListItemText: {
        secondary: {
          textOverflow: "ellipsis",
          overflow: "hidden",
        }
      },
      // MuiMenuItem: {
      //   root: {
      //     color: "#52CBC9  !important",
      //   },
      // },
      MuiLink: {
        root: {
          color: "linear-gradient(45deg, #E1147B 30%, #EA5AA2  90%)",
        },
      },
      MuiTouchRipple: {
        root: {
          color: "#53CBC9 visible !important",
        },
      },
      MuiButton: {
        root: {
          fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
          textTransform: "none"
        },
      },
      MuiTypography: {
        root: {
          fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
          outlineStyle: "#FFFFFF",
          color: "#FFFFFF",
        },
        // body1: {
        //   fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
        // }
      },
    },
  })
);

export default {
  darkTheme: altTheme,
};
