import { createStore } from 'reusable';
import { StringParam, useQueryParam } from 'use-query-params';

import useCoreGeth from '../hooks/useCoreGeth';

export default createStore(() => {
  const [networkQuery] = useQueryParam("network", StringParam);
  const [rpcUrlQuery] = useQueryParam("rpcUrl", StringParam);
  return useCoreGeth(networkQuery || "mainnet", rpcUrlQuery);
});
