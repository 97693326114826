import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { INetwork } from '../NetworkDropdown/networkIface';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (chain: INetwork) => void;
}

const AddChain: React.FC<IProps> = (props: IProps) => {
  const [name, setName] = React.useState<string>();
  const [url, setUrl] = React.useState<string>();
  const [summary, setSummary] = React.useState<string>();

  const handleSubmit = () => {
    if (!name || !url) {
      return;
    }
    const chain: INetwork = { name, url, summary, custom: true };
    console.log("Add chain", chain);
    props.onSubmit(chain);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Chain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a network to the chain list and begin using Expedition with
            your own rpc provider, please enter the required fields below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Network Name"
            type="text"
            fullWidth
            onChange={(v) => setName(v.target.value)}
          />
          <TextField
            margin="dense"
            id="url"
            label="RPC Endpoint"
            type="text"
            fullWidth
            onChange={(v) => setUrl(v.target.value)}
          />
          <TextField
            margin="dense"
            id="summary"
            label="Summary"
            type="text"
            fullWidth
            onChange={(v) => setSummary(v.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddChain;
