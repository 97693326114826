import * as React from "react";
import { useTranslation } from "react-i18next";

import ClearIcon from "@material-ui/icons/Clear";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { INetwork } from "./networkIface";
import ListItemText from "@material-ui/core/ListItemText";

interface IProps {
  networks: INetwork[];
  selectedNetwork?: INetwork;
  setSelectedNetwork?: (network: INetwork) => void;
  deleteNetwork?: (network: INetwork) => void;
}

const NetworkDropdown: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { selectedNetwork, setSelectedNetwork } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (network: INetwork) => {
    setAnchorEl(null);
    if (setSelectedNetwork) {
      setSelectedNetwork(network);
    }
  };

  const handleDeleteNetwork = (network: INetwork) => {
    props.deleteNetwork && props.deleteNetwork(network);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("Change Network") as string}>
        <Button onClick={handleClick}>
          {selectedNetwork && selectedNetwork.name}
        </Button>
      </Tooltip>
      <Menu
        className="dropdown-menu"
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 70 * 6.5,
            width: "250px",
          },
        }}
      >
        {props.networks.map((network, i) => (
          <MenuItem
            selected={selectedNetwork && selectedNetwork.name === network.name}
            onClick={(event) => handleMenuItemClick(network)}
            key={network.name}
            dense
          >
            {/* <div>
              <Typography variant="button">{network.name}</Typography>
              <Typography variant="caption">{network.summary}</Typography>
            </div> */}
            <Tooltip title={network.summary || ""}>
              <ListItemText
                primary={network.name}
                secondary={network.summary}
              />
            </Tooltip>
            <ListItemSecondaryAction>
              {network.custom && (
                <IconButton
                  style={{ minWidth: "0px" }}
                  onClick={() => handleDeleteNetwork(network)}
                  edge="end"
                  aria-label="delete"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NetworkDropdown;
