import "./App.css";

import { createBrowserHistory } from "history";
import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Route, Router, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import useInterval from "use-interval";
import {
  QueryParamProvider,
  StringParam,
  useQueryParams,
} from "use-query-params";

import EthereumJSONRPC from "@etclabscore/ethereum-json-rpc";
import ETHJSONSpec from "@etclabscore/ethereum-json-rpc-specification/openrpc.json";
// import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import { AddChainIcon } from "./icons";

import { ThemeProvider } from "@material-ui/styles";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import AddChain from "./components/AddChain/AddChain";
import NetworkDropdown from "./components/NetworkDropdown/NetworkDropdown";
import { INetwork } from "./components/NetworkDropdown/networkIface";
import Address from "./containers/Address";
import Block from "./containers/Block";
import BlockRawContainer from "./containers/BlockRawContainer";
import Dashboard from "./containers/Dashboard";
import LanguageMenu from "./containers/LanguageMenu";
import MinerStatsPage from "./containers/MinerStatsPage";
import NodeView from "./containers/NodeView";
import Transaction from "./containers/Transaction";
import TransactionRawContainer from "./containers/TransactionRawContainer";
import { createPreserveQueryHistory } from "./helpers/createPreserveHistory";
import fetchAndMergeConfigurations from "./helpers/fetchMergeConfig";
import store from "./helpers/jsonStore";
import expeditionLogo from "./logo.svg";
import useCoreGethStore from "./stores/useCoreGethStore";
import { altTheme } from "./themes/altTheme";

const history = createPreserveQueryHistory(createBrowserHistory, [
  "network",
  "rpcUrl",
])();

function App(props: any) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const theme = altTheme;

  const [selectedNetwork, setSelectedNetwork] = useState<INetwork>();
  const [erpc, setCoreGethUrlOverride]: [EthereumJSONRPC, Dispatch<string>] =
    useCoreGethStore();
  const [networks, setNetworks] = useState<INetwork[]>([]);

  const [addChainDialogIsOpen, setAddChainDialogIsOpen] =
    useState<boolean>(false);

  const [query, setQuery] = useQueryParams({
    network: StringParam,
    rpcUrl: StringParam,
  });

  useEffect(() => {
    if (selectedNetwork === undefined) {
      return;
    }
    const { name } = selectedNetwork as any;

    if (query.network === "_default_" && networks) {
      setQuery({ network: networks[0].name });
      setSelectedNetwork(networks[0]);
      return;
    }

    if (name !== query.network) {
      setQuery({ network: name });
      history.push({
        pathname: history.location.pathname,
        search: `?network=${name}`,
      });
    }
    setCoreGethUrlOverride(selectedNetwork.url);
    toast.info(
      `Connecting to network ${selectedNetwork.name} (${selectedNetwork.url})`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork, setQuery]);

  useEffect(() => {
    fetchAndMergeConfigurations().then((n) => {
      console.log("set networks", n);
      setNetworks(n.networks);
    });
  }, []);

  useEffect(() => {
    if (!networks || networks.length === 0) {
      return;
    }
    const cfg = store.get("config", { networks: [] });
    cfg.networks = networks;
    store.set("config", cfg);
    if (query.rpcUrl) {
      setSelectedNetwork({ name: query.rpcUrl, url: query.rpcUrl });
      return;
    }
    if (networks)
      if (!selectedNetwork) {
        let net;
        if (query.network) {
          net = networks.find((n) => n.name === query.network);
        } else {
          net = networks[0];
        }
        setSelectedNetwork(net);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networks]);

  useEffect(() => {
    if (!networks || networks.length === 0) {
      return;
    }
    if (query.rpcUrl) {
      return;
    }
    if (networks && query.network) {
      const foundNetwork = networks.find((net) => net.name === query.network);
      if (foundNetwork === undefined) {
        toast.error(`network ${query.network} not found, redirecting`);
        setTimeout(() => setSelectedNetwork(networks[0]), 2000);
      }
      if (foundNetwork === selectedNetwork) return;
      setSelectedNetwork(foundNetwork);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.network]);

  const openAddChainModal = () => {
    setAddChainDialogIsOpen(true);
  };

  const cancelAddChainDialog = () => {
    setAddChainDialogIsOpen(false);
  };

  const submitAddChainDialog = (net: INetwork) => {
    const name = net.name.toLowerCase();
    if (name.length > 12) {
      toast.error("Name length shoule be less then 12 characters");
      return;
    }
    // if (net.summary && net.summary.length > 28) {
    //   toast.error("Summary length shoule be less then 28 characters");
    //   return;
    // }
    if (networks) {
      if (
        name === "_default_" ||
        networks.filter((n) => n.name.toLowerCase() === name).length > 0
      ) {
        toast.error("Name Already Exists.");
        return;
      }
    }
    setNetworks(networks.concat(net));
    setAddChainDialogIsOpen(false);
    setSelectedNetwork(net);
  };

  const deleteNetwork = (net: INetwork) => {
    console.log("delete network", net);
    if (!net.custom) return;
    const nets = [...networks];
    const index = nets.indexOf(net);
    if (index > -1) {
      nets.splice(index, 1);
    }
    setNetworks(nets);
    if (selectedNetwork && selectedNetwork.name === net.name && nets) {
      setSelectedNetwork(nets[0]);
    }
    console.log("after delete network", networks);
  };

  React.useEffect(() => {
    if (erpc) {
      erpc.startBatch();
    }
  }, [erpc]);

  useInterval(
    () => {
      if (erpc) {
        erpc.stopBatch();
        erpc.startBatch();
      }
    },
    100,
    true
  );

  const isAddress = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Address.pattern);
    return re.test(q);
  };

  const normalizeHex = (s: string): string =>
    s.startsWith("0x") ? s : "0x" + s;

  const isKeccakHash = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Keccak.pattern);
    return re.test(q);
  };

  const isBlockNumber = (q: string): boolean => {
    const re = new RegExp(/^-{0,1}\d+$/);
    return re.test(q);
  };

  const handleSearch = async (qry: string | undefined) => {
    if (qry === undefined) {
      return;
    }
    const q = qry.trim();
    if (isAddress(normalizeHex(q))) {
      const addr = normalizeHex(q);
      setSearch(addr as any);
      history.push(`/address/${addr}`);
    } else if (isKeccakHash(normalizeHex(q))) {
      const hash = normalizeHex(q);
      setSearch(hash as any);
      let transaction;

      try {
        transaction = await erpc.eth_getTransactionByHash(hash);
      } catch (e) {
        // do nothing
      }

      if (transaction) {
        history.push(`/tx/${hash}`);
      }
      let block;
      try {
        block = await erpc.eth_getBlockByHash(hash, false);
      } catch (e) {
        // do nothing
      }
      if (block) {
        history.push(`/block/${hash}`);
      }
    } else if (isBlockNumber(q)) {
      const block = await erpc.eth_getBlockByNumber(
        `0x${parseInt(q, 10).toString(16)}`,
        false
      );
      if (block) {
        history.push(`/block/${block.hash}`);
      }
    } else {
      toast.error(
        "Unknown search input, should be one of Address, Transaction Hash or Block Number"
      );
    }
  };

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          style={{ height: "92px" }}
        >
          <Container maxWidth="xl">
            <Toolbar style={{ height: "48px", marginTop: "14px" }}>
              <Grid
                justify="space-between"
                alignItems="center"
                alignContent="center"
                container
              >
                <Grid item md={3} xs={12}>
                  <Link href={"/" + window.location.search} underline="none">
                    <Grid>
                      <img
                        alt="expedition-logo"
                        height="32"
                        src={expeditionLogo}
                      />
                      {/* <Divider orientation="vertical" variant="middle" flexItem />
                    <p id="goHomeLogo">{"EXPLORER"}</p> */}
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    className="searchInput"
                    variant="outlined"
                    placeholder={t(
                      "Enter an Address, Transaction Hash or Block Number"
                    )}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                      if (event.key === "Enter") {
                        handleSearch(search);
                      }
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      setSearch(value as any);
                    }}
                    value={search}
                    fullWidth
                    style={{
                      fontSize: "14px",
                      backgroundColor: "#17171c",
                      border: "transparent",
                      borderRadius: "50px",
                    }}
                  />
                </Grid>
                <Grid id="AppButtonBar">
                  <NetworkDropdown
                    networks={networks}
                    setSelectedNetwork={setSelectedNetwork}
                    selectedNetwork={selectedNetwork}
                    deleteNetwork={deleteNetwork}
                  />
                  <Tooltip title={t("Add custom chain") as string}>
                    <IconButton onClick={openAddChainModal}>
                      <AddChainIcon />
                    </IconButton>
                  </Tooltip>
                  <LanguageMenu />
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
        <AddChain
          open={addChainDialogIsOpen}
          onCancel={cancelAddChainDialog}
          onSubmit={submitAddChainDialog}
        />
        <Container id="mainContent" maxWidth="xl">
          <QueryParamProvider ReactRouterRoute={Route}>
            <CssBaseline />
            <Switch>
              <Route path={"/"} component={Dashboard} exact={true} />
              <Route
                path={"/stats/miners"}
                component={MinerStatsPage}
                exact={true}
              />
              <Route path={"/stats/miners/:block"} component={MinerStatsPage} />
              <Route path={"/block/:hash/raw"} component={BlockRawContainer} />
              <Route path={"/block/:hash"} component={Block} />
              <Route path={"/blocks/:number"} component={NodeView} />
              <Route
                path={"/tx/:hash/raw"}
                component={TransactionRawContainer}
              />
              <Route path={"/tx/:hash"} component={Transaction} />
              <Route path={"/address/:address/:block"} component={Address} />
              <Route path={"/address/:address"} component={Address} />
            </Switch>
          </QueryParamProvider>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
