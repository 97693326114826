export default {
  get: (key: string, defaultValue?: any) => {
    const data = window.localStorage.getItem(key)
    if (!data) {
      return defaultValue
    }
    return JSON.parse(data)
  },

  set: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }
}
