import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Typography } from '@material-ui/core';

export interface IAddressViewProps {
  address: string;
  balance: string;
  txCount: number;
  code: string;
}

function AddressView(props: IAddressViewProps) {
  const { address, balance, txCount, code } = props;
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t("Address")}: {address}</Typography>
        <Typography variant="h6">{t("Balance")}: {balance}</Typography>
        <Typography variant="h6">{t("Transactions")}: {txCount}</Typography>
        <br />
        <div>
          <div>{t("Code")}</div>
          <pre>
            <code style={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>{code}</code>
          </pre>
        </div>
      </CardContent>
    </Card>
  );
}

export default AddressView;
