import * as React from "react";
import { useTranslation } from "react-i18next";
import useInterval from "use-interval";

import { hexToNumber } from "@etclabscore/eserialize";
import EthereumJSONRPC, {
  Block as IBlock,
  IsSyncingResult as ISyncing,
} from "@etclabscore/ethereum-json-rpc";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTheme } from "@material-ui/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import ChartCard from "../components/ChartCard";
import StatCard from "../components/StatCard";
import StatCharts from "../components/StatCharts";
import { weiToGwei } from "../components/formatters";
import getBlocks, { useBlockNumber } from "../helpers";
import {
  BlockHeightIcon,
  ChainIDIcon,
  GasPriceIcon,
  PeersIcon,
} from "../icons";
import useCoreGethStore from "../stores/useCoreGethStore";
import getTheme from "../themes/victoryTheme";
import BlockListContainer from "./BlockList";

const useState = React.useState;

const config = {
  blockTime: 15, // seconds
  blockHistoryLength: 100,
  chartHeight: 200,
  chartWidth: 400,
};

export default (props: any) => {
  const [erpc]: [EthereumJSONRPC, any] = useCoreGethStore();
  const theme = useTheme<Theme>();
  const victoryTheme = getTheme(theme);
  const [blockNumber] = useBlockNumber(erpc);
  const [chainId, setChainId] = useState<string>();
  const [, setBlock] = useState<IBlock>();
  const [blocks, setBlocks] = useState<IBlock[]>();
  const [gasPrice, setGasPrice] = useState<string>();
  const [syncing, setSyncing] = useState<ISyncing>();
  const [peerCount, setPeerCount] = useState<string>();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.eth_chainId().then((cid) => {
      if (cid === null) {
        return;
      }
      setChainId(cid);
    });
  }, [erpc]);

  React.useEffect(() => {
    if (!erpc || blockNumber === undefined) {
      return;
    }
    erpc
      .eth_getBlockByNumber(`0x${blockNumber.toString(16)}`, true)
      .then((b) => {
        if (b === null) {
          return;
        }
        setBlock(b);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber]);

  React.useEffect(() => {
    if (!erpc || blockNumber === null) {
      return;
    }
    getBlocks(
      Math.max(blockNumber - config.blockHistoryLength + 1, 0),
      blockNumber,
      erpc
    ).then((bl) => {
      setBlocks(bl);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber]);

  useInterval(
    () => {
      if (!erpc) {
        return;
      }
      erpc.eth_syncing().then(setSyncing);
    },
    10000,
    true
  );

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.net_peerCount().then(setPeerCount);
  }, [erpc]);

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.eth_gasPrice().then(setGasPrice);
  }, [erpc]);

  if (!blocks || !chainId || !gasPrice || !peerCount) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="column"
        style={{
          paddingTop: "30px",
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Grid item container justify="space-between">
          <Grid item key="blockHeight" md={3} sm={6} xs={12}>
            <StatCard
              title={t("Block Height")}
              icon={<BlockHeightIcon viewBox="0 0 40 40" />}
              value={blockNumber}
            />
          </Grid>
          <Grid item key="chainId" md={3} sm={6} xs={12}>
            <StatCard
              title={t("Chain ID")}
              icon={<ChainIDIcon viewBox="0 0 40 40" />}
              value={hexToNumber(chainId)}
            />
          </Grid>
          {syncing && (
            <Grid item key="syncing">
              <ChartCard title={t("Syncing")}>
                {typeof syncing === "object" && syncing.currentBlock && (
                  <Typography variant="h4">
                    {hexToNumber(syncing.currentBlock)} /{" "}
                    {hexToNumber(syncing.highestBlock || "0x0")}
                  </Typography>
                )}
              </ChartCard>
            </Grid>
          )}
          <Grid item key="gasPrice" md={3} sm={6} xs={12}>
            <StatCard
              title={t("Gas Price")}
              icon={<GasPriceIcon viewBox="0 0 40 40" />}
            >
              <Typography>
                {weiToGwei(hexToNumber(gasPrice))}
                {" Gwei"}
              </Typography>
            </StatCard>
          </Grid>
          <Grid key="peers" item md={3} sm={6} xs={12}>
            <StatCard
              title={t("Peers")}
              icon={<PeersIcon viewBox="0 0 40 40" />}
              value={hexToNumber(peerCount)}
            />
          </Grid>
        </Grid>
      </Grid>
      <StatCharts victoryTheme={victoryTheme} blocks={blocks} />
      <Grid container justify="center">
        <Button
          color="primary"
          size="small"
          endIcon={<ArrowForwardIcon />}
          onClick={() => props.history.push("/stats/miners")}
        >
          {t("More Stats")}
        </Button>
      </Grid>
      <BlockListContainer
        from={Math.max(blockNumber - 14, 0)}
        to={blockNumber}
        disablePrev={true}
        disableNext={blockNumber < 14}
        onNext={() => {
          props.history.push(`/blocks/${blockNumber - 15}`);
        }}
        style={{ marginTop: "30px" }}
      />
    </div>
  );
};
