export default {
    // Moment integration
    "Timestamp Date": "{{date, date}}",
    "Change Language": "Изменить Язык",
    "Enter an Address, Transaction Hash or Block Number": "Поиск по адресу / Хэш транзакции / Блоку",
    "JSON-RPC API Documentation": "Документация JSON-RPC API",
    "Expedition Github": "Expedition Github",
    "Toggle Dark Mode": "Дневной / Ночной режим",
    "Block Height": "Высота Блока",
    "Chain ID": "ID Сети",
    "Syncing": "Синхронизация",
    "Gas Price": "Цена на газ",
    "Network Hash Rate": "Скорость Хэширования Сети",
    "Nodes": "Узлы",
    "Hash Rate": "Block transactions / Block size",
    "Transaction Count": "Количество Транзакций",
    "Gas Usage": "Использованный Газ",
    "Uncles": "Gas used / Block transactions",
    "Top Miners": "Топ Майнеры",
    "Top Miners by extraData": "Топ Майнеры по экстраДате",
    "Top Miners by Address": "Топ Майнеры по Адресу",
    "Block Number": "Высота Блока",
    "Hash": "Хэш",
    "Timestamp": "Временной Штамп",
    "Transactions": "Транзакции",
    "Number": "Номер",
    "ParentHash": "Родительский Хэш",
    "Miner": "Майнер",
    "Nonce": "Нонс",
    "Difficulty": "Сложность",
    "Extra Data": "Дополнительные Данные",
    "State Root": "Корень Состояния",
    "Transaction Root": "Корень Транзакции",
    "Receipts Root": "Receipts Root",
    "Block": "Блок",
    "Gas Used": "Использованный Газ",
    "Cumulative Gas Used": "Общее Количество Использованного Газа",
    "Value in Ether": "Стоимость в Эфире",
    "From": "Отправитель",
    "To": "Получатель",
    "Contract Address": "Адрес Контракта",
    "Transaction Index": "Индекс Транзакции",
    "Receipt Status": "Описание Статуса",
    "Receipt Logs": "Описание Логов",
    "No Transactions for this block range.": "Нет транзакций для этого диапазона блоков.",
    "Address": "Адрес",
    "Balance": "Баланс",
    "Code": "Код",
    "Configuration": "Конфигурация",
    "Back": "Назад",
    "Author": "Автор",
    "Custom RPC" : "Custom RPC",
  };
