import * as React from "react";
import * as ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReusableProvider } from "reusable";
import "./i18n";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <ReusableProvider>
      <App />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ReusableProvider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);
