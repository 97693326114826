import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

interface IProps {
  children?: any;
  value?: any;
  title: string;
  icon: any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "transparent",
    },
    icon: {
      "& svg": {
        color: theme.palette.primary.main,
        fill: "none",
        width: "40px",
        height: "40px",
      },
      marginRight: "12px",
    },
    title: {
      marginTop: "5px",
      color: "#A4A4B2",
      fontWeight: 600,
      fontSize: "13px",
      height: "18px",
      lineHeight: "18px",
    },
    value: {
      marginTop: "4px",
      "& .MuiTypography-root": {
        fontWeight: "bold",
        fontSize: "24px",
        height: "36px",
        lineHeight: "36px",
      },
    },
  })
);

const StatCard: React.FC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.root}>
      <CardContent>
        <Grid container direction="row">
          <Grid className={classes.icon}>{props.icon}</Grid>
          <Grid>
            <Grid>
              <Typography className={classes.title}>{props.title}</Typography>
            </Grid>
            <Grid className={classes.value}>
              {props.value && <Typography>{props.value}</Typography>}
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatCard;
