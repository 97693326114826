import _ from 'lodash';
import { toast } from 'react-toastify';

import store from './jsonStore';

interface INetwork {
  name: string;
  url: string;
  summary?: string;
  custom?: boolean;
  [k: string]: any;
}
export type TNetwork = INetwork;
interface IConfig {
  networks: INetwork[]
}

function merged<T>(array1: T[], array2: T[], key: string): T[] {
  var merged = _.merge(_.keyBy(array1, key), _.keyBy(array2, key));
  var values = _.values(merged);
  return values
}

const fetchAndMergeConfigurations = async (configUrl?: string) => {
  const url = configUrl || `${process.env.PUBLIC_URL}/config.json`
  const cfg = store.get('config', { networks: [] }) as IConfig
  try {
    const resp = await fetch(url)
    const remoteCfg = (await resp.json()) as IConfig
    console.log("fetch config, remote/local", remoteCfg, cfg)
    cfg.networks = merged(remoteCfg.networks, cfg.networks, 'name')
    store.set('config', cfg)
    return cfg
  } catch (e) {
    toast.error("Fail to fetch config file from server, will retry", { onClose: () => window.location.reload() })
    throw e
  }
}

export default fetchAndMergeConfigurations;
